import { useElementVisibility } from "@vueuse/core";
import { v4 as uuid } from "uuid";
import { useTippy } from "vue-tippy";

import type { Directive } from "vue";

const directive: Directive = {
  mounted(el: HTMLElement) {
    const id = uuid();
    const text = el.innerText;
    const words = text.split(" ");
    words[words.length - 1] = `<span id="${id}"">${
      words[words.length - 1]
    }</span>`;
    el.innerHTML = words.join(" ");

    const lastTextWordElVisible = useElementVisibility(
      document.getElementById(id)
    );

    useTippy(el, {
      onShow: () => {
        if (lastTextWordElVisible.value) {
          return false;
        }
      },
      content: text
    });
  }
};

export default directive;
