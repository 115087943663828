import type { App, Plugin } from "vue";
import { vOnClickOutside } from "@vueuse/components";

const plugin: Plugin = {
  install: (app: App<Element>) => {
    app.directive("on-click-outside", vOnClickOutside);
  }
};

export default plugin;
